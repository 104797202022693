import React from "react"
import styled from "styled-components"
import Heading from "../../assets/elements/heading"
import { theme } from "../../utils/theme"
import { PostDate } from "../PostDate"

const StyledHeader = styled.header`
  margin-bottom: ${theme.spacing * 4}px;
`

interface PageTitle {
  date?: string
}

export const BlogTitle: React.FC<PageTitle> = ({ date, children }) => (
  <StyledHeader>
    <Heading size={1}>{children}</Heading>
    {date && <PostDate>{date}</PostDate>}
  </StyledHeader>
)
