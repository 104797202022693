import { graphql, Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { BlogPostBySlugQuery } from "../../graphql-types"
import About from "../components/About"
import { BlogTitle } from "../components/BlogTitle"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { theme } from "../utils/theme"

interface BlogPostTemplate {
  data: BlogPostBySlugQuery
  location: Location
  pageContext: any
}

const StyledSection = styled.section`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    color: var(--heading);
  }

  p {
    margin-bottom: ${theme.spacing * 3}px;
  }

  h2 {
    margin-top: ${theme.spacing * 6}px;
    margin-bottom: ${theme.spacing * 3}px;
  }

  h3 {
    margin-top: ${theme.spacing * 4}px;
    margin-bottom: ${theme.spacing * 2}px;
  }

  h4,
  h5,
  h6 {
    margin-bottom: ${theme.spacing}px;
  }

  ul,
  ol {
    padding-left: ${theme.spacing * 3}px;
    margin-bottom: ${theme.spacing * 3}px;
  }

  li {
    padding-left: ${theme.spacing}px;
    p {
      margin: 0;
    }
  }
`

const BlogPostTemplate: React.FC<BlogPostTemplate> = props => {
  const post = props.data.markdownRemark
  const siteTitle = props.data.site?.siteMetadata?.title || ""
  const { previous, next } = props.pageContext

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO
        title={post?.frontmatter?.title || ""}
        description={post?.frontmatter?.description || post?.excerpt || ""}
      />
      <article>
        <BlogTitle date={post?.frontmatter?.date}>
          {post?.frontmatter?.title}
        </BlogTitle>
        <StyledSection dangerouslySetInnerHTML={{ __html: post?.html || "" }} />
        <footer>
          <About />
        </footer>
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
