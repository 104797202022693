import styled from "styled-components"
import { device, theme } from "../../utils/theme"
import Image from "gatsby-image"

export const StyledAbout = styled.div`
  display: flex;
  border-top: 1px solid var(--border);
  padding-top: ${theme.spacing * 3}px;

  @media ${device.desktop.s} {
    flex-direction: row;
  }
`

export const StyledAuthor = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const StyledImage = styled(Image)`
  border-radius: 50%;
  min-width: 80px;
  margin-right: ${theme.spacing * 2}px;

  @media ${device.desktop.s} {
    width: 100px;
  }
`

export const StyledWrittenBy = styled.div`
  font-size: 1rem;
`
