import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import {
  StyledAbout,
  StyledAuthor,
  StyledImage,
  StyledWrittenBy,
} from "./styles"
import Twitter from "../../assets/images/twitter.svg"

const About: React.FC = () => {
  const data = useStaticQuery(graphql`
    query AboutQuery {
      avatar: file(absolutePath: { regex: "/amber-image.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            twitter
          }
        }
      }
    }
  `)

  const { author } = data.site.siteMetadata

  return (
    <StyledAbout>
      <StyledImage fluid={data.avatar.childImageSharp.fluid} alt={author} />
      <StyledAuthor>
        <StyledWrittenBy>Written by </StyledWrittenBy>
        <div>
          <a
            key="twitter"
            href={
              "https://www.twitter.com/" +
              data.site?.siteMetadata?.social?.twitter
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {author} <Twitter />
          </a>
        </div>
      </StyledAuthor>
    </StyledAbout>
  )
}

export default About
